import {Link, graphql} from 'gatsby'
import Container from './../components/container'
import Fade from 'react-reveal/Fade'
import Layout from './../components/layout'
import PageHeading from './../components/page-heading'
import React, {useState} from 'react'
import styled from 'styled-components'

export default props => {
  const {data} = props

  const prismicData = {}

  return (
    <Layout documentTitlePrefix="Page Not Found" isIndexPage={false} pageName="page-not-found" showScrollToTopBtn={false}>
      <PageNotFoundWrapper>
        <PageHeading className="page-heading-404" heading="Page Not Found" />
        <Container>
          <Fade>
            <StyledNotFoundMessage>
              <h3>404</h3>
              <p>Sorry, the page you are looking for cannot be found.</p>
              <p>
                Try going somewhere else using the navigation, or{' '}
                <Link to="/" className="header-heading-link">
                  click here to go to the homepage
                </Link>
                .
              </p>
            </StyledNotFoundMessage>
          </Fade>
        </Container>
      </PageNotFoundWrapper>
    </Layout>
  )
}

const PageNotFoundWrapper = styled.section`
  margin-top: 2rem;
`

const StyledNotFoundMessage = styled.div`
  font-weight: 100;
  margin-top: 4rem;
  text-align: center;

  h3 {
    font-size: 13rem;
    font-weight: 100;
  }

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }
`
